import React from "react";

import { HomeContainer } from "./styles.jsx";
//Components
import HomeSlides from "../../views/MainSlides/HomeSlides.jsx";
import FloatingButtonUse from "../../views/FloatingButton/FloatingButton.jsx";

import Footer2 from "../../views/Footer2/Footer2.jsx";
import withRoot from "../../Styles/withRoot.js";
import ProductCategories from "../../views/ProductCategories.js";
import ProductCTA from "../../views/ProductCTA.js";
import WorkShopVideos from "../../views/WorkShopVideos/WorkShopVideos.js";
const Home = () => {
  return (
    <>
      <HomeSlides></HomeSlides>
      <HomeContainer>
        <FloatingButtonUse></FloatingButtonUse>
        <WorkShopVideos></WorkShopVideos>
        <ProductCategories />
        <ProductCTA />
      </HomeContainer>
      <Footer2></Footer2>
    </>
  );
};
export default withRoot(Home) ;
