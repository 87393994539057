import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import Iframe from 'react-iframe'
function ProductCTA() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'warning.main',
              py: 8,
              px: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
              <Typography variant="h2" component="h2" gutterBottom>
                ¿Tienes dudas?
              </Typography>
              <Typography variant="h5">
                Solicita informes
              </Typography>
              <TextField
                noBorder
                placeholder="Ingresa tu email"
                variant="standard"
                sx={{ width: '100%', mt: 3, mb: 2 }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
              >
               Enviame información
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >

            <Iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14884.231143850353!2d-101.6937188!3d21.1500985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbf215e0aa7b9%3A0x24493a27807416e5!2sCl%C3%ADnica%20Integra%20Health%20%26%20Care!5e0!3m2!1ses-419!2smx!4v1681875051729!5m2!1ses-419!2smx"
        width='450px'
        height='350px'
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        sx={{
          position: 'absolute',
          top: -28,
          left: -28,
          right: 0,
          bottom: 0,
          width: '100%',
          maxWidth: 600,
        }}
      ></Iframe>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will send you our best offers, once a week."
      />
    </Container>
  );
}

export default ProductCTA;
