import React from "react";
import { Container } from "./styles.jsx";
import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const FloatingButtonUse = () => {
  return (
    <Container>
      <Fab
        href="https://api.whatsapp.com/send?phone=524777974003"
        color="success"
        aria-label="add"
        target={"_blank"}
      >
        <WhatsAppIcon />
      </Fab>
    </Container>
  );
};

export default FloatingButtonUse;
