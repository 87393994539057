import styled from "styled-components";

export const HomeContainer = styled.div`
width: 100%;
margin: 0 auto;
max-width: 1410px;
padding-left: 20px;
padding-right: 20px;
@media only screen and (min-width: 1450px) {
  padding-left: 0px;
  padding-right: 0px;
}
`;
