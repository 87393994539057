import React, { useState } from "react";
//Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReactPlayer from "react-player";
//Styles
import { MainContainer } from "./styles";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Parallax, Pagination, Navigation } from "swiper";
const HomeSlides = () => {
  const [isPlaying, setIsPlaying] = useState(null);
  const videos = [
    {
      id: 1,
      url: "https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4",
    },
    {
      id: 2,
      url: "https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4",
    },
    {
      id: 3,
      url: "https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4",
    },
  ];
  return (
    <section id="home" className="works clearfix">
      <MainContainer>
        <Swiper
          onSlideChange={() => {
            setIsPlaying(null);
          }}
          autoplay={true}
          watchSlidesProgress={true}
          style={{
            "--swiper-navigation-color": "black",
            "--swiper-pagination-color": "black",
          }}
          speed={1000}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          loop={true}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
          className="mySwiper"
        >
          <div
            slot="container-start"
            className="parallax-bg"
            style={{
              "background-color": "#DED7C6",
            }}
            data-swiper-parallax="-53%"
          ></div>
          {videos.map((data) => (
            <SwiperSlide key={data.id}>
              <div className="info-container">
                <div className="title" data-swiper-parallax="-1300">
                  Cultivando {data.id}
                </div>
                <div className="subtitle" data-swiper-parallax="-1200">
                  Subtitle
                </div>
                <div className="text" data-swiper-parallax="-1100">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam dictum mattis velit, sit amet faucibus felis iaculis
                    nec. Nulla laoreet justo vitae porttitor porttitor.
                    Suspendisse in sem justo. Integer laoreet magna nec elit
                    suscipit, ac laoreet nibh euismod. Aliquam hendrerit lorem
                    at elit facilisis rutrum. Ut at ullamcorper velit. Nulla
                    ligula nisi, imperdiet ut lacinia nec, tincidunt ut libero.
                    Aenean feugiat non eros quis feugiat.
                  </p>
                </div>
              </div>
              <ReactPlayer
                className="video-class"
                key={data.id}
                url={data.url}
                controls={false}
                autoplay={true}
                playsinline={true}
                onPlay={() => {
                  setIsPlaying(data.id);
                }}
                playing={isPlaying === data.id}
                width={"auto"}
                height={"auto"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/*<Carousel
          interval={10000}
          showThumbs={false}
          autoPlay={true}
          showStatus={false}
          axis={"horizontal"}
          centerMode={false}
          showArrows={false}
          
        >
          <div>
            <video
              id="comp-ju6pgrm7_video"
              className="video-slide"
              role="presentation"
              crossorigin="anonymous"
              playsinline=""
              preload="auto"
              muted=""
              loop=""
              tabindex="-1"
              autoplay="true"

              //style="height: 100%; width: 100%; object-fit: cover; object-position: center center; opacity: 1;"
            >
              <source
                src="https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4"
                type="video/mp4"
              />
            </video>
            <p className="legend">Fisioterapia</p>
          </div>
          <div>
            <video
              id="comp-ju6pgrm7_video"
              className="video-slide"
              role="presentation"
              crossorigin="anonymous"
              playsinline=""
              preload="auto"
              muted=""
              loop=""
              tabindex="-1"
              autoplay="true"
            >
              <source
                src="https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4"
                type="video/mp4"
              />
            </video>
            <p className="legend">Fisioterapia</p>
          </div>
          <div>
            <video
              id="comp-ju6pgrm7_video"
              className="video-slide"
              role="presentation"
              crossorigin="anonymous"
              playsinline=""
              preload="auto"
              muted=""
              loop=""
              tabindex="-1"
              autoplay="true"

              //style="height: 100%; width: 100%; object-fit: cover; object-position: center center; opacity: 1;"
            >
              <source
                src="https://video.wixstatic.com/video/11062b_1a5fb336450c45e6bd32006b3ff0d4bf/1080p/mp4/file.mp4"
                type="video/mp4"
              />
            </video>
            <p className="legend">Fisioterapia</p>
          </div>
              </Carousel>*/}
      </MainContainer>
    </section>
  );
};
export default HomeSlides;
