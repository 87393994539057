import styled from "styled-components";

export const ContainerNavBar = styled.div`
  /* Side Menu */
  * {
    font-family: "Lena", sans-serif !important;
  }
  .a-link {
    cursor: pointer;
  }
  .logo {
    position: absolute;
    z-index: 99999;
    left: 50px;
    top: 20px;
  }
  .logo i {
    font-size: 50px;
    color: #fff;
  }
  span {
    color: #fff;
    vertical-align: middle;
    margin-left: 9px;
    font-size: 24px;
    font-family: "Source Sans Pro", sans-serif;
    text-transform: capitalize;
  }
  #sidebar-wrapper {
    position: fixed;
    z-index: 2;
    right: 0;
    width: 250px;
    height: 100%;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    transform: translateX(250px);
    background: rgba(0, 0, 0, 0.7);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #sidebar-wrapper .sidebar-nav li.sidebar-nav-item a {
    display: block;
    text-decoration: none;
    color: #fff;
    padding: 15px;
  }
  #sidebar-wrapper .sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  #sidebar-wrapper .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  #sidebar-wrapper .sidebar-nav > .sidebar-brand {
    font-size: 20px;
    height: 80px;
    line-height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  #sidebar-wrapper .sidebar-nav > .sidebar-brand a {
    color: #fff;
  }
  #sidebar-wrapper .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
  }
  #sidebar-wrapper.active {
    right: 250px;
    width: 250px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    z-index: 9999;
  }
  .menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;
    background: rgba(52, 58, 64, 0.5);
    line-height: 50px;
    z-index: 99999;
    cursor: pointer;
  }
  .menu-toggle:focus,
  .menu-toggle:hover {
    color: #fff;
  }
  .menu-toggle:hover {
    background: #343a40;
  }
  /******************************/
`;
