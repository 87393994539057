import React from 'react';
import './App.css';
import NavBar from './views/NavBar/navBar';
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom';
import Home from './pages/Home/home';
import AppBarView from './views/AppBarView/AppBarView';

function App() {
  return (
    <Router>
      <NavBar />
      {/*<AppBarView/>*/}
      <Routes>
        <Route path='/' exact element={<Home />}></Route>
        <Route path='/about' element={<Home />} />
        <Route path='/events' component={<Home />} />
        <Route path='/annual' component={<Home />} />
        <Route path='/team' component={<Home />} />
        <Route path='/blogs' component={<Home />} />
        <Route path='/sign-up' component={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
