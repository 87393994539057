import React, { useState } from "react";

//Styles
import { ContainerNavBar } from "./styles";
//libraries
import { Link } from "react-scroll";
//icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
//Assets
import logo from "../../assets/img/cultivando.png";
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ContainerNavBar>
      <div class="logo">
        <img width={50} src={logo} alt="" />
      </div>
      <a
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? "menu-toggle rounded active" : "menu-toggle rounded"
        }`}
        href
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </a>
      <nav id="sidebar-wrapper" className={`${isOpen ? "active" : ""}`}>
        <ul className="sidebar-nav">
          <li className="sidebar-brand">
            {/*Para dejar espacio arriba de las opciones*/}
          </li>
          <li className="sidebar-nav-item">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="a-link"
              onClick={() => setIsOpen(!isOpen)}
            >
              Inicio
            </Link>
          </li>
          <li className="sidebar-nav-item">
            <Link
              activeClass="active"
              to="servicios"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="a-link"
              onClick={() => setIsOpen(!isOpen)}
            >
              Servicios
            </Link>
          </li>
          <li className="sidebar-nav-item">
            <Link
              activeClass="active"
              to="workshop"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="a-link"
              onClick={() => setIsOpen(!isOpen)}
            >
              Workshop
            </Link>
          </li>
        </ul>
      </nav>
    </ContainerNavBar>
  );
};

export default NavBar;
