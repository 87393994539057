import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 999;

  .video-class {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
  }

  .swiper {
    width: 100%;
    height: 100%;
    background: #000;
  }
  .info-container {
    z-index: 99999;
    position: relative;
    margin-top: 100px;
  }
  .swiper-slide {
    font-size: 18px;
    color: black;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }

  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }

  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
    font-family: Lena;
  }

  .swiper-slide .subtitle {
    font-size: 21px;
  }

  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
`;
